.preloaderr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: hsl(240, 6%, 7%);
}

.preloaderr img {
  height: 120px;
  animation: load 2s ease infinite;
}

@keyframes load {
  30% {
    transform: scale(1.2);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.loader {
  margin: auto;
  margin-top: 48px;
  border: 6px solid #fff; /* Light grey */
  border-top: 6px solid hsl(44, 100%, 39%); /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Toastify__toast {
  font-family: "Inter", sans-serif;
}

.Toastify__toast-body {
  font-family: "Inter", sans-serif;
}

/* styles.css */
.skeleton-image,
.skeleton-text {
  background: linear-gradient(
    to right,
    #404044 0%,
    #5c5c5e 20%,
    #404044 40%,
    #404044 100%
  );
  background-size: 200% 100%;
  animation: shimmer 3.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeleton-image {
  width: 100%;
  height: 200px; /* Adjust height as needed */
}

.skeleton-text {
  height: 20px;
  width: 100px; /* Adjust width as needed */
  margin-bottom: 10px;
}

.skeleton-title {
  height: 30px;
  width: 80%;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 30px;
}

.pp {
  margin: auto;
  margin-top: 18px;
  max-width: 400px;
}

.notfound-btn::before {
  background-color: #c79200;
}

input[type="file"] {
  display: none;
}

.modal-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(17, 17, 19, 0.8);
}

.tit {
  font-size: 30px;
}

@media (min-width: 768px) {
  .tit {
    font-size: 50px;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (min-width: 1072px) {
  .vid-w {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .left {
    width: 58%;
  }

  .right {
    width: 42%;
    margin-top: 50px;
  }

  .leftt {
    width: 56%;
  }

  .rightt {
    width: 43%;
    margin-top: 0;
  }

  .favbtn {
    width: 80%;
    margin: auto;
  }
}
